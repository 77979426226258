@import "/srv/www/assets/styles/_mixins.scss";
@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  @font-face {
    font-family: "Montserrat";
    src: url("/assets/styles/fonts/Montserrat/Montserrat-Regular.ttf");
  }

  @font-face {
    font-family: "Montserrat";
    font-weight: 700;
    src: url("/assets/styles/fonts/Montserrat/Montserrat-Bold.ttf");
  }

  @font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: url("/assets/styles/fonts/Montserrat/Montserrat-Medium.ttf");
  }

  @font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: url("/assets/styles/fonts/Montserrat/Montserrat-Light.ttf");
  }

  #app {
    @apply flex min-h-screen;
  }

  body {
    @apply bg-gray;
  }

  .admin-container {
    @apply min-w-fit w-full flex flex-col;
  }

  .text-bhp {
    @apply text-green;
  }

  .text-marso {
    @apply text-red;
  }

  /* Head css */
  .head {
    @apply w-full min-w-lg bg-red flex h-16 sticky top-0 z-10;
  }

  .head .left-side .tab {
    @apply bg-red-light h-full text-white flex text-base font-bold px-5 items-center min-w-80 mr-7 last:mr-0 cursor-pointer;
  }

  .head .left-side {
    @apply w-7/12 flex;
  }

  .head .left-side .tab .icon {
    @apply ml-auto stroke-white stroke-30 text-lg;
  }

  .head .right-side {
    @apply flex text-white uppercase font-bold ml-auto items-center text-sm mr-16;
  }

  .head .right-side .head-link {
    @apply flex mr-7 last:mr-0 cursor-pointer h-fit py-3 items-center;
  }

  .head .right-side .head-link .icon {
    @apply ml-3 text-lg scale-125 relative;
  }

  .head .right-side .head-link .icon .notifications {
    @apply text-xxs font-normal h-4 w-5 scale-100 text-white bg-gray-notification rounded flex items-center justify-center absolute top-0 right-0 translate-x-3.5 -translate-y-0.5;
  }

  .head .right-side .head-link .icon.shop-icon {
    @apply flex justify-center items-center;
  }

  .head .right-side .head-link .icon.shop-icon .circle {
    @apply text-2xl rotate-90;
  }

  .head .right-side .head-link .icon.shop-icon .arrow-icon {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs;
  }

  /* Navbar css */

  .navbar {
    @apply min-w-80 w-2/12 bg-white min-h-screen max-h-screen h-full duration-700 overflow-hidden sticky top-0 shadow-nav;
  }

  .navbar .nav-top {
    @apply h-16 flex items-center bg-red;
  }

  .navbar .logo {
    @apply ml-16;
  }

  .navbar .logo .open {
    @apply w-32 2xl:w-auto;
  }

  .navbar .logo img {
    @apply max-w-full;
  }

  .navbar .nav-top .arrow {
    @apply h-full w-16 ml-auto flex items-center justify-center text-white text-sm cursor-pointer;
  }

  .navbar .name {
    @apply text-center text-red uppercase font-bold text-2xl py-8 px-2;
  }

  .navbar .nav-link .router-link-exact-active {
    @apply font-bold block bg-gray;
  }

  nav .nav-items .nav-link a .nav-link-inner {
    @apply text-lg w-4/5 ml-auto py-8 flex align-middle items-center;
  }

  nav .nav-items .nav-link a svg {
    @apply ml-auto text-gray-dark text-base pr-7 font-bold stroke-gray-dark stroke-30;
  }

  nav .nav-items .nav-link {
    @apply border-gray-border border-b first:border-t;
  }

  nav .nav-items .nav-link .nav-link-icon {
    @apply w-5 mr-2.5;
  }

  nav .nav-items .nav-link .nav-link-icon img {
    @apply max-w-full h-auto;
  }

  nav .nav-items .nav-link a.active {
    @apply font-bold;
  }

  nav .nav-items .nav-link.dropdown a {
    @apply cursor-pointer;
  }

  nav .nav-items .nav-link.dropdown .dropdown-menu {
    @apply h-0 overflow-hidden duration-500;
  }

  nav .nav-items .nav-link.dropdown .dropdown-menu .nav-link a .nav-link-inner {
    @apply py-3;
  }

  nav .nav-items .nav-link.dropdown .dropdown-menu.show {
    @apply h-auto w-auto block relative opacity-100;
  }

  nav.closed .nav-items .nav-link.dropdown .dropdown-menu {
    @apply hidden;
  }

  /*.dropdown:has(> a.router-link-exact-active) {
    @apply font-bold;
  }*/

  .navbar .logo .closed {
    @apply hidden;
  }

  /* Navbar closed css */
  .navbar.closed {
    @apply w-24 min-w-24;
  }

  .navbar.closed .nav-items a .nav-link-inner .nav-text,
  .navbar.closed .nav-items a .nav-link-inner svg {
    @apply w-0 opacity-0 p-0 m-0;
  }

  .navbar.closed .nav-items .nav-link .nav-link-icon {
    @apply w-8 mr-auto ml-auto;
  }

  .navbar.closed .nav-items .nav-link a .nav-link-inner {
    @apply w-full;
  }

  .navbar.closed .name {
    @apply text-xs;
  }

  .navbar.closed .logo .open {
    @apply hidden;
  }

  .navbar.closed .logo {
    @apply ml-5;
  }

  .navbar.closed .logo .closed {
    @apply block;
  }

  .navbar.closed .nav-top .arrow {
    @apply rotate-180 w-8;
  }

  /* Full calendar osztályok */

  .main-container {
    @apply pl-7 pt-5 pr-16 flex-grow flex text-base w-full max-w-[1770px] min-w-[1440px];
  }

  .calendar-container {
    @apply flex flex-nowrap mb-12;
  }

  .calendar-container .fc-event {
    @apply border-0 rounded-none;
  }

  .calendar-container .fc-event .event-title {
    @apply text-black;
  }

  .calendar-container .event-from-marso-flotta {
    @apply border-t-4 border-red-light bg-red-light bg-opacity-40;
  }

  .calendar-container .fc-timegrid-slot-lane{
    @apply cursor-pointer;
  }

  .calendar-container .event-from-bhp {
    @apply border-t-4 border-green bg-green-light;
  }

  .calendar-container .event-top-border{
    @apply border-t-4;
  }

  .calendar-container .rejected-appointment{
    @apply opacity-50;
  }

  .calendar-container .event-from-marso {
    @apply border-t-4 border-red bg-red-extra-light;
  }

  .calendar-container .event-from-online {
    @apply border-t-4 border-blue bg-blue-light;
  }

  .calendar-container .event-from-service {
    @apply border-t-4 border-yellow bg-yellow-light;
  }

  .calendar-container .fc-timegrid-slot {
    @apply bg-white h-4 border-solid border-l-0;
  }

  .calendar-container .fc-timegrid-slot-label {
    @apply border-r-0;
  }

  .calendar-container > .calendar {
    @apply mr-7 w-68;
  }

  .calendar-container > .calendar:last-child {
    @apply mr-0;
  }

  .calendar-container thead .fc-timegrid-axis {
    @apply hidden;
  }

  .calendar-container thead .fc-scrollgrid-sync-inner {
    @apply bg-gray-darker text-white uppercase h-14 flex items-center px-2.5 py-2;
  }

  .calendar-container
    thead
    .fc-scrollgrid-sync-inner
    .fc-col-header-cell-cushion {
    @apply text-sm;
  }

  .calandar-column {
    @apply w-1/5 text-sm font-bold mr-7 last:mr-0;
  }

  .calendar-container .fc-timegrid-body .fc-timegrid-slots table {
    @apply h-full;
  }

  .calendar-container .fc .fc-toolbar.fc-header-toolbar {
    @apply mb-0;
  }

  .calendar-container .fc-event-main {
    @apply flex items-center pl-5 text-sm font-bold leading-4;
  }

  .calendar-container .past-slot .fc-timegrid-slot {
    @apply bg-gray;
  }

  .calendar-container
    .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    @apply text-sm;
  }

  .calendar-container .hour-border {
    @apply border-t-2 border-gray-border;
  }

  .calendar-container .circle-mark.exclamation {
    @apply w-6 h-6 inline-block rounded-full absolute right-1 top-1 fill-orange z-20;
  }

  .calendar-container .circle-mark.exclamation svg {
    @apply rounded-full bg-origin-content;
  }

  .calendar-container .circle-mark.exclamation .background {
    @apply w-4 h-4 left-1 top-1 absolute -z-10 block bg-white;
  }

  /* Calendar Footer */
  .calendar-footer {
    @apply h-20 bg-white sticky bottom-0 z-30 flex items-center pl-7 pr-10 mx-0.5;
  }

  .calendar-footer .footer-item {
    @apply mr-12;
  }

  .footer-item {
    @apply flex items-center min-w-min;
  }

  .footer-item .circle {
    @apply relative overflow-hidden w-7 h-7 rounded-full mr-2.5;
  }

  .bg-online {
    @apply bg-blue;
  }

  .bg-bhp {
    @apply bg-green;
  }

  .bg-service {
    @apply bg-yellow;
  }

  .bg-marso {
    @apply bg-red;
  }

  .bg-marso-flotta {
    @apply bg-red-light;
  }

  .footer-item .icon {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center;
  }

  .footer-item .text {
    @apply text-base max-w-min;
  }

  .footer-item .icon .fontawsome-icon {
    @apply text-3xl;
  }

  .calendar-footer .left,
  .calendar-footer .right {
    @apply flex items-center;
  }

  .calendar-footer .right {
    @apply ml-auto;
  }

  /* Inputok formázása */

  .form-input input,
  .form-input select,
  .form-input textarea {
    @apply px-5 text-base;
  }

  .form-input textarea {
    @apply p-3;
  }

  .form-input label {
    @apply text-lg font-medium mb-2.5 leading-5 block;
  }

  .form-input .label-text {
    @apply text-lg font-medium mb-2.5 leading-5 block;
  }

  .form-input input,
  .form-input select {
    @apply h-14;
  }

  .form-input input.bold,
  .form-input select.bold {
    @apply font-bold;
  }

  .form-input input.bold::placeholder {
    @apply font-bold text-black;
  }

  .form-input {
    @apply relative mt-2.5;
  }

  .form-input .input-col {
    @apply flex relative;
  }

  .form-input .input-col .currency {
    @apply w-16 bg-[#C4C4C4] text-gray-dark flex items-center justify-center text-lg font-bold;
  }

  .form-input .search-icon {
    @apply absolute top-1/2 right-5 text-black -translate-y-1/2 text-xl pointer-events-none z-10;
  }

  .form-input .radio-label {
    @apply bg-gray w-full h-full pt-5 pb-3 cursor-pointer;
  }

  .form-input .radio-label input {
    @apply hidden;
  }

  .form-input .radio-label .img-container {
    @apply h-20 w-auto opacity-30;
  }

  .form-input .radio-label .img-container img {
    @apply max-w-full max-h-full relative left-1/2 -translate-x-1/2;
  }

  .form-input .radio-label .radio-text {
    @apply text-center mt-2 text-base opacity-30;
  }

  .form-input .radio-label.checked .img-container {
    @apply opacity-100;
  }

  .form-input .radio-label.checked .radio-text {
    @apply opacity-100;
  }

  .form-input .radio-label {
    @apply border-2 border-gray;
  }

  .form-input .radio-label.checked {
    @apply border-2 border-blue rounded;
  }

  .form-input.button {
    @apply mt-7;
  }

  .form-input.file {
    @apply mt-7;
  }

  .form-input .file-input {
    @apply bg-transparent border-2 rounded border-gray flex flex-col items-center justify-center w-full h-36 cursor-pointer relative;
  }

  .form-input .file-input .content {
    @apply text-center;
  }

  .form-input .file-input .file-input-text {
    @apply font-bold text-xl mt-3;
  }

  .form-input .file-input .icon {
    @apply text-6xl;
  }

  .form-input .file-input input {
    @apply hidden;
  }

  .custom-btn {
    @apply w-full text-left pl-5 pr-3 flex items-center uppercase text-lg font-bold text-white h-14;
  }

  .custom-btn .icon {
    @apply ml-auto;
  }

  .custom-btn.button-success {
    @apply bg-green;
  }

  .btn {
    @apply h-14;
  }

  .filters .form-input{
    @apply w-full
  }

  .filters .form-input input{
    @apply w-full
  }

  .filters .input-group{
    @apply items-center
  }

  .custom-btn.button-danger {
    @apply bg-red;
  }

  .custom-btn.button-default {
    @apply bg-gray-def;
  }

  .custom-btn.button-primary {
    @apply bg-blue;
  }

  .custom-btn.button-transparent {
    @apply bg-transparent text-blue;
  }

  .switch {
    @apply flex cursor-pointer select-none items-center mt-5;
  }

  .switch-block {
    @apply relative;
  }

  .switch .switch-base {
    @apply block h-6 w-14 rounded-full bg-gray-border;
  }

  .switch .switch-dot {
    @apply absolute -left-1 -top-1 flex h-8 w-8 items-center font-bold justify-center rounded-full transform duration-300 bg-red text-white;
  }

  .switch .switch-text {
    @apply ml-4 font-bold;
  }

  .switch-card .switch .switch-text {
    @apply w-24;
  }

  .login-wrapper .form-input input{
    @apply pl-10 w-full
  }

  /*foglalások lista */

  .reservations-list {
    @apply min-w-full w-full;
  }

  .reservations-list .list-top {
    @apply flex min-w-full;
  }

  .reservations-list .list-top .left,
  .reservations-list .list-top .right {
    @apply flex;
  }

  .reservations-list .list-top .right {
    @apply ml-auto items-center;
  }

  .reservations-list .list-top .right .text {
    @apply uppercase font-bold mr-5;
  }

  .reservations-list .list-top .right .period-selects {
    @apply flex mr-7;
  }

  .reservations-list .list-top .right .period-selects .form-input:first-child {
    @apply mr-2.5;
  }

  .reservations-list .list-top .right .element {
    @apply mr-7;
  }

  .reservations-list .list-top .right .element:last-child {
    @apply mr-0;
  }

  .reservations-list .list-top .right .element .form-input select {
    @apply w-72;
  }

  .reservations-list .list-table {
    @apply mt-7 border-separate border-spacing-y-5 w-full max-w-full;
  }

  .reservations-list .list-table .table-head.table-row {
    @apply bg-gray-darker text-white font-bold uppercase w-full mt-0;
  }

  .reservations-list .list-table .table-row {
    @apply h-20 border-spacing-y-1.5 bg-white text-left;
  }

  .reservations-list .list-table .table-row td,
  .reservations-list .list-table .table-row th {
    @apply px-4 max-w-56 relative;
  }

  .reservations-list .list-table .table-row td:nth-child(8),
  .reservations-list .list-table .table-row th:nth-child(8) {
    @apply text-center;
  }

  .reservations-list .list-table .table-row td .booking-color {
    @apply w-1 h-full absolute top-0 left-0;
  }

  /*Reservation Modal*/

  .modal-layer {
    @apply fixed inset-0 bg-opacity-50 transition-opacity bg-black z-40;
  }

  .reservation-modal .modal-body {
    @apply fixed max-w-screen-2xl min-w-screen-2xl w-full p-6 bg-white rounded-md shadow-xl left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50;
  }

  .reservation-modal .modal-body .modal-head {
    @apply flex items-center justify-between;
  }

  .reservation-modal .modal-body .modal-head .modal-title {
    @apply text-2xl;
  }

  .reservation-modal .modal-body .modal-content {
    @apply mt-4 flex relative;
  }

  .reservation-modal .modal-body .modal-content .close-btn {
    @apply text-gray-def absolute right-0 top-0 -translate-y-7 translate-x-3 cursor-pointer w-6 h-6 flex items-center justify-center;
  }

  .reservation-modal .modal-body .modal-content .reservation-model-col {
    @apply w-full px-5 flex flex-col;
  }

  .reservation-modal .modal-body .modal-content .reservation-model-col input,
  .reservation-modal .modal-body .modal-content .reservation-model-col select,
  .reservation-modal .modal-body .modal-content .reservation-model-col textarea,
  .full-form input,
  .full-form textarea {
    @apply bg-gray w-full text-black font-medium;
  }

  .reservation-modal .modal-body .modal-content .reservation-model-col .title {
    @apply text-2xl font-bold uppercase h-16 mb-5 leading-8 flex items-center;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .form-input:first-child {
    @apply mt-0;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .radio-container {
    @apply flex items-center;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .radio-container
    .form-input {
    @apply w-1/2 mt-0 mr-2.5;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .radio-container {
    @apply mt-2.5;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .radio-container
    .form-input:last-child {
    @apply mr-0;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .form-input.button {
    @apply mt-auto;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .form-input.button
    button {
    @apply mt-7;
  }

  .form-input .checkbox-label {
    @apply flex items-center cursor-pointer;
  }

  .form-input .checkbox-label input {
    @apply w-7 h-7 hidden;
  }

  .form-input .checkbox-label .checkbox-text {
    @apply text-lg ml-2.5;
  }

  .form-input .checkbox-label .checkbox-div {
    @apply w-7 h-7 bg-gray rounded;
  }

  .form-input .checkbox-label .checkbox-div > span {
    @apply items-center justify-center w-full h-full;
  }

  .form-input .checkbox-label .checkbox-div span {
    @apply text-sm;
  }

  .form-input.file {
    @apply mt-10;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .form-input.file
    .file-input {
    @apply bg-transparent border-2 rounded border-gray-border flex flex-col items-center justify-center w-full h-36 text-black;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .modal-reservation {
    @apply border-2 rounded text-xl h-20 flex items-center justify-center;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .modal-reservation
    span {
    @apply text-center font-bold;
  }

  .reservation-modal
    .modal-body
    .modal-content
    .reservation-model-col
    .modal-status {
    @apply h-14 flex items-center pl-2 bg-gray mt-11;
  }

  .reservation-modal .modal-body .status-history {
    @apply mt-10 text-lg;
  }

  .reservation-modal .modal-body .status-history .texts {
    @apply pr-3;
  }

  .reservation-modal .modal-body .status-history .history-title {
    @apply font-bold;
  }

  .reservation-modal .modal-body .status-history .history-block {
    @apply mt-6 flex items-center;
  }

  .reservation-modal .modal-body .status-history .history-block .icon {
    @apply ml-auto text-4xl text-gray-def;
  }

  .reservation-modal .modal-body .checkbox-container {
    @apply mb-1 mt-1;
  }

  /* Szervizek listázva */

  .form-input.button.w-98 {
    @apply mt-0;
  }

  .card-container {
    @apply flex flex-wrap max-w-screen-2xl;
  }

  .card {
    @apply w-98 bg-white p-5 mr-7 text-sm mt-7;
  }

  .card-container .card:nth-child(3n) {
    @apply mr-0;
  }

  .card-container .card .card-title {
    @apply font-bold text-2xl;
  }

  .card-container .card .status-title.title {
    @apply mt-5;
  }

  .card-container .card .title {
    @apply mt-2.5 font-bold;
  }

  .card-container .card .status.value {
    @apply font-bold uppercase mt-2.5;
  }

  .card-container .card .value {
    @apply mt-1.5;
  }

  .card-container .card .status.value.active {
    @apply text-green;
  }

  .card-container .card .status.value.inactive {
    @apply text-red;
  }

  .card-container .col-container {
    @apply flex;
  }

  .card-container .col-container .right-side {
    @apply ml-16;
  }

  .card-container .col-container table tr td:first-child {
    @apply pr-4;
  }

  .card-container .col-container table tr td:nth-child(2n) {
    @apply whitespace-nowrap;
  }

  .card-container .col-container .title {
    @apply mb-2.5;
  }

  .card-container .col-container table tr td,
  .card-container .col-container .right-side .item {
    @apply py-0.5;
  }

  .card-container .card .form-input.button {
    @apply mt-5;
  }

  .card-container .card .form-input.button .icon {
    @apply ml-0 text-3xl mr-2.5;
  }

  .card-container .card .form-input.button .custom-btn {
    @apply text-sm h-auto pl-0;
  }

  /* Form */

  .h2-title {
    @apply w-full h-auto text-3xl font-bold uppercase;
  }

  .flex-container {
    @apply flex mt-12;
  }

  .flex-container .left-side {
    @apply mr-7 w-3/5 flex flex-col;
  }

  .flex-container .right-side {
    @apply w-2/5 flex flex-col;
  }

  .flex-container .right-side .service-card {
    @apply flex flex-col w-full;
  }

  .flex-container .form-input {
    @apply w-full flex flex-col mt-5;
  }

  .flex-container .form-input textarea {
    @apply h-full max-h-max;
  }

  .flex-container .form-input input {
    @apply w-full;
  }

  .form-input.text-area {
    @apply h-[93%];
  }

  .flex-container .divided-col {
    @apply flex w-full relative;
  }

  .flex-container .divided-col .col {
    @apply mr-8 w-full;
  }

  .flex-container .divided-col .col:last-child {
    @apply mr-0;
  }

  .switch-card {
    @apply flex flex-wrap w-full;
  }

  .switch-card .card-col {
    @apply mr-auto;
  }

  .card-label {
    @apply text-lg font-medium mt-4;
  }

  .flex-container .right-side .card {
    @apply mt-1.5;
  }

  .switch-card,
  .service-card {
    @apply pt-1;
  }

  .flex-container .right-side .button-row {
    @apply flex mt-auto;
  }

  .flex-container .right-side .button-row .form-input.button {
    @apply w-68 mr-auto ml-5;
  }

  .flex-container .right-side .button-row .form-input.button:first-child {
    @apply ml-0;
  }

  .flex-container .right-side .button-row .form-input.button:last-child {
    @apply mr-0;
  }

  /* Location Form Header*/

  .form-container .form-navigation {
    @apply flex w-full;
  }

  .form-container .form-navigation .form-nav-button {
    @apply w-full text-gray-def bg-nav-shadow uppercase border-white border-r-4 h-14 font-semibold text-base;
  }

  .form-container .form-navigation .form-nav-button.active {
    @apply bg-white text-black;
  }

  .form-container .form-navigation .form-nav-button:last-child {
    @apply border-r-0;
  }

  /* Location Form*/

  .form-container {
    @apply flex flex-col w-full mb-36;
  }

  .form-container .full-form {
    @apply px-5 py-6 bg-white;
  }

  .form-container .button-row {
    @apply flex;
  }

  .button-row .form-input.button {
    @apply w-68 mr-7;
  }

  .button-row .form-input.button:first-child {
    @apply ml-auto;
  }

  .button-row .form-input.button:last-child {
    @apply mr-0;
  }

  .form-container .full-form .card {
    @apply bg-gray;
  }

  .form-container .full-form .card .card-title {
    @apply text-lg;
  }

  .card-rows {
    @apply mt-4;
  }

  .card-rows .card-row {
    @apply flex font-medium border-t-2 border-gray-def;
  }

  .card-rows .card-row:last-child {
    @apply border-b-2;
  }

  .card-rows .card-row > div {
    @apply w-1/2 py-2.5;
  }

  .card-rows .card-row > div.right {
    @apply text-right;
  }

  .form-page .columns {
    @apply flex flex-wrap w-full;
  }

  .form-page .columns .col {
    @apply w-1/3 pr-5;
  }

  .form-page .columns .col.card:nth-child(3n) {
    @apply pr-0;
  }

  .form-page .check-box-container {
    @apply mt-7;
  }

  .form-page .check-box-container .check-box-title {
    @apply text-lg h-7 font-bold;
  }

  .form-data-container {
    @apply flex flex-col;
  }

  .form-data-container .row {
    @apply flex w-full mt-5;
  }

  .form-data-container .row:first-child {
    @apply mt-0;
  }

  .form-data-container .row .row-head {
    @apply flex items-center justify-center w-24 bg-form-col-head text-white font-bold mr-7;
  }

  .form-data-container .row .row-head span {
    @apply -rotate-90;
  }

  .form-data-container .row .inputs {
    @apply w-full flex flex-wrap;
  }

  .form-data-container .row .inputs .form-input {
    @apply w-full;
  }

  .form-data-container .row .inputs .multiple-inputs {
    @apply w-full flex;
  }

  .form-data-container .row .inputs .multiple-inputs .form-input {
    @apply mr-7;
  }

  .form-data-container .row .inputs .multiple-inputs .form-input:last-child {
    @apply mr-0;
  }

  .full-form .form-data-container .form-input .file-input {
    @apply bg-gray text-gray-def flex-row h-full justify-start pl-10 box-border;
  }

  .full-form .form-data-container .form-input .file-input .content {
    @apply flex w-96 text-lg items-center;
  }

  .full-form .form-data-container .form-input .file-input .content .icon {
    @apply mr-10 text-8xl;
  }

  .full-form .form-data-container .form-input.file {
    @apply mt-2.5 flex flex-col;
  }

  .form-data-container .row .inputs .multiple-inputs.address .form-input.zip {
    @apply w-1/6;
  }

  .form-data-container .row .inputs .multiple-inputs.address .form-input.city {
    @apply w-2/6;
  }

  .form-data-container
    .row
    .inputs
    .multiple-inputs.address
    .form-input.address {
    @apply w-3/6;
  }

  .form-data-container .row .inputs .column:first-child {
    @apply w-2/6 pr-4;
  }

  .form-data-container .row .inputs .column:last-child {
    @apply w-4/6 pl-2;
  }

  /* Bankend home page*/

  .new-appointment-container{
    @apply bg-white p-8 w-full
  }

  /* w-110 a design szerinti szélesség de egyelőre nem kellenek a statisztikák!*/

  .home-title{
    @apply text-2xl uppercase font-bold text-black mr-4
  }

  .appointment-head{
    @apply flex items-center pb-8 border-b-1 border-black
  }

  .link{
    @apply text-xl text-blue underline font-medium ml-auto
  }

  .new-appointment-container .item{
    @apply pb-3 border-b-1 border-gray-border mt-3 flex text-lg
  }

  .new-appointment-container .item .datas .top{
    @apply text-xs
  }

  .new-appointment-container .item .datas{
    @apply mr-4
  }

  .new-appointment-container .item .buttons{
    @apply ml-auto
  }

  .new-appointment-container .item .buttons button{
    @apply mr-7
  }

  .new-appointment-container .item .buttons button:last-child{
    @apply mr-0
  }

  


}
