<template>
  <div>
    <FullCalendar
      :key="renderKey"
      :options="calendarOptions"
      class="custom-columns"
      :data-service-bay="props.serviceBayId"
    />
    <div
      v-if="showContextMenu"
      ref="contextMenuElement"
      :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
      class="context-menu"
      @click="hideContextMenu"
    >
      <!--div v-if="showClipMenuPoint" @click="clippingEvent">Clip</div-->
      <div
        v-if="fullCalendarStore.clipboard && showPasteMenuPoint"
        class="menu-point"
        @click="pasteEvent"
      >
        {{ $trans("paste_button", "Paste", "fullcalendar") }}
      </div>
      <span
        v-if="!showClipMenuPoint && !fullCalendarStore.clipboard"
        class="text-red font-bold"
      >
        {{
          $trans(
            "nothig_on_clipboard",
            "Nothing on the clipboard!",
            "fullcalendar"
          )
        }}
      </span>

      <VueDatePicker
        v-model="date"
        class="date-picker"
        locale="hu"
        :enable-time-picker="false"
        :show-buttons="false"
        auto-apply
        :clearable="false"
        close-on-scroll
        :markers="calendarMarkers"
        position="left"
        :min-date="new Date()"
        @update:model-value="getSelectedDate"
        @update-month-year="handleMonthYear"
      >
        <template #trigger>
          <div
            v-if="showClipMenuPoint"
            class="menu-point"
            @click="clippingEvent"
          >
            {{ $trans("clip_button", "Clip", "fullcalendar") }}
          </div>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script setup lang="ts">
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import huLocale from "@fullcalendar/core/locales/hu";
import { CalendarOptions, EventClickArg } from "@fullcalendar/core";
import { ref, Ref, onMounted, onUnmounted } from "vue";
import { EventImpl } from "@fullcalendar/core/internal";
import { useFullCalendarStore } from "../../stores/fullCalendar/fullCalendarStore";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import trans from "../../common/i18n";
//import { Translator } from "../../common/i18n";
//import { cloneDeep } from "lodash";

const fullCalendarStore = useFullCalendarStore();

const handleMonthYear = ({ month, year }: { month: number; year: number }) => {
  if (props.locationId !== 0) {
    emit(
      "calendarHandleMonthYear",
      year,
      month,
      props.locationId,
      selectedEvent.value?.extendedProps.serviceId
    );
  }
};

interface Marker {
  date: any;
  type: any;
  color: any;
  tooltip: any;
}

const renderKey = ref(0);
const props = defineProps<{
  title: string;
  appointments: Array<any>;
  selectedDate: Date;
  locationId: number;
  serviceBayId: number;
  calendarMarkers: Array<Marker>;
}>();

// eslint-disable-next-line vue/no-dupe-keys
const title: Ref<String> = ref(props.title || "Default title");

const date: Ref<Date> = ref(props.selectedDate);

let calendarOptions: CalendarOptions = {
  plugins: [timeGridPlugin, interactionPlugin],
  initialDate: date.value, // fix dátum beállítás, ha majd backedről jönnek a kapott adatok akkor ki kell venni

  initialView: "timeGridDay",
  headerToolbar: {
    left: "",
    center: "",
    right: "",
  },
  eventClick: (info: EventClickArg) => {
    //bal kattintásra
    openEvent(info);
  },
  eventDragStart: function (info) {
    fullCalendarStore.draggedFrom = info;
  },
  eventDidMount: (arg) => {
    //Jobb kattintásra event kiváltása
    arg.el.addEventListener("contextmenu", (jsEvent) => {
      showClipMenuPoint.value = true;
      showPasteMenuPoint.value = false;
      showCreateNewMenuPoint.value = false;
      jsEvent.preventDefault();
      const mouseEvent = jsEvent as MouseEvent;
      contextMenuX.value = mouseEvent.pageX;
      contextMenuY.value = mouseEvent.pageY;
      showContextMenu.value = true;
      selectedEvent.value = arg.event;
    });
  },
  eventDrop: function (info) {
    if (
      window.confirm(
        trans.trans(
          "confirm_copy",
          "Are you sure you want to reschedule this appointment?",
          "fullcalendar"
        )
      )
    ) {
      // Adott timegriden belüli áthelyezésnél ez fut le
      if (info.event.start && info.event.end) {
        pasteEventAfter(
          parseInt(info.event.id),
          info.event.start,
          info.event.end,
          props.serviceBayId
        );
      }
    } else {
      info.revert();
    }
  },

  eventReceive: function (info) {
    if (
      window.confirm(
        trans.trans(
          "confirm_copy",
          "Are you sure you want to reschedule this appointment?",
          "fullcalendar"
        )
      )
    ) {
      //Másik timegridbe helyezésnél (service bay) ez fut le
      if (info.event.start && info.event.end) {
        pasteEventAfter(
          parseInt(info.event.id),
          info.event.start,
          info.event.end,
          props.serviceBayId
        );
      }
    } else {
      //mivel külön timegridben van egy globális storeban kell lekövetni a változásokat az info.revert csak eltávolítja az áthelyezett timegridből de nem állítja vissza az eredeti timegridbe
      if (fullCalendarStore.draggedFrom) {
        const calendar = fullCalendarStore.draggedFrom.view.calendar;
        calendar.addEvent(fullCalendarStore.draggedFrom.event);
        info.revert();
        fullCalendarStore.draggedFrom = null;
      }

      //console.log(fullCalendarStore.draggedFrom);
    }
  },

  eventResize: function (info) {
    if (
      window.confirm(
        trans.trans(
          "confirm_resize",
          "Are you sure you want to resize this date?",
          "fullcalendar"
        )
      )
    ) {
      // Event átméretezése
      if (info.event.end && info.event.start) {
        pasteEventAfter(
          parseInt(info.event.id),
          info.event.start,
          info.event.end,
          props.serviceBayId
        );
      }
    } else {
      info.revert();
    }
  },
  dateClick: function (info) {
    emit(
      "createNewAppointment",
      info.date,
      props.serviceBayId,
      props.locationId
    );
  },
  events: props.appointments,
  selectable: false,
  editable: true,
  droppable: true,
  slotDuration: "00:10:00", // 10 perces idősávok
  contentHeight: 2000,
  allDaySlot: false, // Ez eltávolítja az "all-day" szöveget
  locale: huLocale, // Magyar lokalizáció beállítása
  slotLabelFormat: {
    hour: "2-digit",
    minute: "2-digit",
    omitZeroMinute: false,
    meridiem: false,
  },

  expandRows: true, // Automatikus magasság beállítása
  slotMinTime: "08:00", // A naptár kezdő ideje 8:00, változóból jöhet ez is mint nyitvatartás
  slotMaxTime: "19:00", // A naptár befejező ideje 19:00, változóból jöhet ez is mint nyitvatartás

  // Állítsd be az egyedi szöveget az első oszlop fejlécében
  dayHeaderContent: (args) => {
    // Zászló az első oszlop azonosítására
    let isFirstColumn = false;

    // Ellenőrizzük, hogy az aktuális oszlop az első-e
    if (args.view.currentStart.toISOString() === args.date.toISOString()) {
      isFirstColumn = true;
    }

    // Ha az aktuális oszlop az első oszlop
    if (isFirstColumn) {
      return { html: title.value };
    }

    // Különben térj vissza az alapértelmezett fejléc formátummal (hét napja, hónap napja)
    return {
      html: args.date.toLocaleDateString(undefined, {
        weekday: "short",
        month: "numeric",
        day: "numeric",
      }),
    };
  },
  eventContent: (args) => {
    const event = args.event;
    //const from = event.extendedProps.from;
    //${event.title}
    let mark;

    if (event.extendedProps.status === "New") {
      const svgExclamationCircle = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`;
      mark = document.createElement("span");
      mark = document.createElement("span");
      mark.classList.add("circle-mark", "exclamation");
      mark.innerHTML =
        '<span class="background"></span>' + svgExclamationCircle;
    } else {
      mark = document.createElement("span");
    }

    //let wheelText = event.extendedProps.isWheel ? "Felnivel" : "Felni nélkül";
    let wheelText = event.extendedProps.isWheel
      ? trans.trans("with_rim", "With rim", "fullcalendar")
      : trans.trans("without_rim", "Without rim", "fullcalendar");

    return {
      html: `
        <div class="event-wrapper">
          ${mark.outerHTML}
          <div class="event-title">
            <div class="gyarto">${event.extendedProps.gyarto} ${event.extendedProps.modell}</div>
            <div class="rendszam">${wheelText}</div>
            </div>
        </div>
      `,
    };
  },

  /*eventClassNames: (args) => {
    const event = args.event;
    const from = event.extendedProps.from;
    return ["event-from-" + from.toLowerCase()];
  },*/

  eventClassNames: (args) => {
    if (args.event.extendedProps.status === "Rejected") {
      return ["event-top-border", "rejected-appointment"];
    } else {
      return ["event-top-border"];
    }
  },

  slotLaneDidMount(info) {
    if (info.date) {
      const slotTime = new Date();
      const infoDate = new Date(info.date.toString());

      slotTime.setHours(infoDate.getHours(), infoDate.getMinutes(), 0);
      slotTime.setFullYear(props.selectedDate.getFullYear());
      slotTime.setMonth(props.selectedDate.getMonth());
      slotTime.setDate(props.selectedDate.getDate());

      const now = new Date();
      const slotElement = info.el.closest("tr");

      if (slotTime < now) {
        if (slotElement) {
          slotElement.classList.add("past-slot");
        }
      }

      if (slotTime.getMinutes() === 0) {
        if (slotElement) {
          slotElement.classList.add("hour-border");
        }
      }
    }
  },
};

let showClipMenuPoint = ref(false);
let showPasteMenuPoint = ref(false);
let showContextMenu = ref(false);
let showCreateNewMenuPoint = ref(false);
let contextMenuX = ref(0);
let contextMenuY = ref(0);
let selectedEvent: Ref<EventImpl | null> = ref(null);
let pasteDate: Ref<Date | null> = ref(null);
let pasteServiceBay: Ref<number> = ref(0);

function hideContextMenu() {
  showContextMenu.value = false;
}

function clippingEvent() {
  handleMonthYear({
    year: props.selectedDate.getFullYear(),
    month: props.selectedDate.getMonth(),
  });
  fullCalendarStore.clipboard = selectedEvent.value;
}

function pasteEvent() {
  if (
    fullCalendarStore.clipboard &&
    pasteDate.value &&
    fullCalendarStore.clipboard.end &&
    fullCalendarStore.clipboard.start
  ) {
    const dateDiff =
      fullCalendarStore.clipboard.end.getTime() -
      fullCalendarStore.clipboard.start.getTime();

    pasteEventAfter(
      parseInt(fullCalendarStore.clipboard.id),
      pasteDate.value,
      new Date(pasteDate.value.getTime() + dateDiff),
      pasteServiceBay.value
    );

    fullCalendarStore.clipboard = null;
    //appointmentsCopy.value.push(newEvent);
  }
}

//Új időpont szöveg megjelenítése mouseover eseményre
onMounted(() => {
  const slots = document.querySelectorAll(".fc-timegrid-slot-lane");
  slots.forEach((slot) => {
    slot.addEventListener("mouseover", handleMouseOver);
    slot.addEventListener("mouseout", handleMouseOut);
  });
});

const handleMouseOver = (event: Event) => {
  const target = event.target as HTMLElement;
  target.innerText = trans.trans(
    "new_appointment",
    "Create new appointment",
    "reservation_modal"
  );
};

const handleMouseOut = (event: Event) => {
  const target = event.target as HTMLElement;
  target.innerText = "";
};

//jobbklikk a time slotra megnyitja a menüt és lekéri az időt belőle
onMounted(() => {
  const handler = (event: Event) => {
    const target = event.target as HTMLElement;

    // Check if the target is a FullCalendar timegrid cell
    const cell = target.closest(".fc-timegrid-slot-lane");

    if (cell) {
      showCreateNewMenuPoint.value = true;
      showClipMenuPoint.value = false;
      showPasteMenuPoint.value = true;
      event.preventDefault();

      // Get the data-time attribute of the cell
      const datetime = cell.getAttribute("data-time");
      if (datetime) {
        // The time is represented as "HH:MM:SS"
        const [hour, minute, second] = datetime.split(":").map(Number);

        // Get the date of the clicked cell
        // Assume we are on the current day
        const date = new Date(props.selectedDate.toISOString());
        date.setHours(hour, minute, second);

        const serviceBayElement = target.closest("[data-service-bay]");
        if (serviceBayElement) {
          const serviceBayId =
            serviceBayElement.getAttribute("data-service-bay");
          if (serviceBayId) {
            pasteServiceBay.value = parseInt(serviceBayId);
          }
        }
        pasteDate.value = date;
        const mouseEvent = event as MouseEvent;
        contextMenuX.value = mouseEvent.pageX;
        contextMenuY.value = mouseEvent.pageY;
        showContextMenu.value = true;
      }
    }
  };

  document.addEventListener("contextmenu", handler);

  onUnmounted(() => {
    document.removeEventListener("contextmenu", handler);
  });
});

// ha a fullcalendaron kívűl kattint akkor eltűnik a menü
let contextMenuElement: Ref<HTMLElement | null> = ref(null);

const handleClickOutside = (e: MouseEvent) => {
  const targetElement = e.target as Node; // e.targetet Node típusra állítjuk
  if (!contextMenuElement.value?.contains(targetElement)) {
    showContextMenu.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

const emit = defineEmits([
  "clickEvent",
  "pasteEventAfter",
  "getSelectedDate",
  "calendarHandleMonthYear",
  "createNewAppointment",
]);

const openEvent = (clickedEvent: EventClickArg) => {
  emit("clickEvent", clickedEvent.event);
};

const pasteEventAfter = (
  id: number,
  newStart: Date,
  newEnd: Date,
  newServiceBay: number
) => {
  emit("pasteEventAfter", id, newStart, newEnd, newServiceBay);
};

const getSelectedDate = (date: Date) => {
  emit("getSelectedDate", date);
};
</script>

<style scoped>
.context-menu {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  width: 250px;
}

.context-menu .menu-point {
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
}

.fc-timegrid-slot-lane {
  cursor: pointer;
}
</style>
