@import "/srv/www/assets/styles/_mixins.scss";

.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
  @apply border-0 bg-transparent text-white;
}

.dp__icon.dp__input_icon.dp__input_icons {
  @apply hidden;
}

.dp__main.dp__theme_light.date-picker > div {
  @apply w-full h-full flex;
}

.dp__main.dp__theme_light.date-picker > div .calendar-text {
  @apply flex items-center;
}
