@import "/srv/www/assets/styles/_mixins.scss";

#back-to-top-button {
  &.visible {
    @apply opacity-80 z-10 transition-opacity duration-300 ease-in;

    &:hover {
      @apply opacity-100;
    }
  }
}
