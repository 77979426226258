@import "/srv/www/assets/styles/_mixins.scss";

.reservation-modal .dp__main.dp__theme_light.date-picker > div {
  @apply block;
}

.reservation-modal .dp__sidebar_left .appointments {
  @apply mt-2;
}

.reservation-modal .dp__sidebar_left .appointments > div {
  @apply py-1 px-2 mt-1 cursor-pointer rounded-lg hover:bg-[#1976d2] hover:text-white transition;
}

.reservation-modal .dp__sidebar_left .appointments > div.selected {
  @apply bg-[#1976d2] text-white;
}

.reservation-modal .dp__action_select {
  @apply bg-[#1976d2] flex justify-center items-center hover:bg-white hover:text-[#1976d2] hover:border-[#1976d2] transition;
}
