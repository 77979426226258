@import "/srv/www/assets/styles/_mixins.scss";

.nav-dropdown {
  > .dropdown-menu {
    @apply absolute left-auto min-w-full w-max flex-col hidden;
    @apply transform-gpu transition-all duration-300 ease-out opacity-0 -translate-y-8 -translate-x-4 scale-75;
    @apply font-normal text-base normal-case bg-white text-gray-darkest justify-start py-2 shadow z-50;

    &.right-0 {
      @apply translate-x-4;
    }
  }

  &.mobile-full-width {
    > .dropdown-menu {
      @apply fixed;
    }
  }

  .dropdown {
    > span {
      @apply p-2 lg:p-0;
    }

    > .dropdown-menu {
      @apply lg:px-0;
    }
  }

  &.disabled::after {
    @apply block absolute left-0 top-0 w-full h-full bg-white opacity-70;

    content: " ";
  }

  &.hoverable:not(.disabled) {
    @include hover-supported() {
      @screen lg {
        &:hover {
          > span {
            .marso-icon-chevron-down {
              @apply rotate-180;
            }
          }

          > .dropdown-menu {
            display: flex;
            opacity: 1;
            //transform: scale(1) translate(0, 0);
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(100%);
            width: 100%;
          }

          .dropdown {
            &:hover {
              > .dropdown-menu {
                @apply relative shadow-inner;
              }
            }
          }
        }
      }
    }
  }

  &.active {
    &:not(.hoverable) {
      @apply border-blue ring-4 ring-blue ring-opacity-50;
    }

    > span {
      .marso-icon-chevron-down {
        @apply rotate-180;
      }
    }

    > .dropdown-menu {
      display: flex;
      opacity: 1;
      //transform: scale(1) translate(0, 0);
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      width: 100%;
      overflow-y: scroll;
      max-height: 300px;
    }

    .dropdown {
      &.active {
        > .dropdown-menu {
          @apply relative shadow-inner;
        }
      }
    }
  }
}
