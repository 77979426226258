@import "/srv/www/assets/styles/_mixins.scss";

table.responsive {
  @apply relative border-separate;
  border-spacing: 0 1rem;

  @screen lg {
    &.condensed {
      @apply border-collapse;
      border-spacing: 0;
    }

    &.panel {
      @apply shadow-sm rounded-lg overflow-hidden;
    }
  }

  thead {
    th {
      @apply px-4 py-2;
    }
  }

  tfoot {
    td {
      @apply px-4 py-2;
    }
  }

  tbody {
    tr {
      @apply block lg:table-row mb-4 bg-white;

      @screen lg {
        @apply mb-0;
      }

      td {
        @apply px-4 py-2;

        .mobile-label {
          @apply block font-bold;

          @screen lg {
            @apply hidden;
          }
        }
      }

      td {
        @apply grid grid-cols-2 border-b border-gray;

        @screen lg {
          @apply table-cell;
        }
      }
    }
  }

  @screen lg {
    &.condensed {
      tbody {
        td {
          @apply px-4 py-1;
        }
      }
    }
  }

  &.panel {
    tbody {
      tr {
        @apply shadow-sm rounded-lg overflow-hidden;

        @screen lg {
          @apply shadow-none rounded-none;
        }
      }
    }
  }

  &.striped {
    tbody {
      tr {
        @apply even:bg-gray;
      }
    }
  }

  &.striped.panel {
    tbody {
      tr {
        @apply bg-white;

        @screen lg {
          @apply even:bg-gray;
        }
      }
    }
  }

  &.busy::after {
    @apply block absolute top-0 left-0 w-full h-full bg-gray-dark opacity-50;
    content: " ";
  }
}
